<script>
import { mapActions, mapGetters } from 'vuex';
import http from '~/http';

export default {
  computed: {
    ...mapGetters(['city', 'user', 'cities']),
  },

  methods: {
    ...mapActions(['logout']),

    handleLogout() {
      http.post('auth/logout').then(() => {
        this.logout();
        this.$router.push({ name: 'login' });
      });
    },
  },
};
</script>

<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="fas fa-bars"></i>
        </a>
      </li>
      <li class="nav-item" v-if="city.name">
        <span class="nav-link">
          Cidade:
          <router-link :to="{ name: 'select.franchisee' }" v-if="cities.length > 1">
            {{ city.name === 'Todas' ? city.name : `${city.name}-${city.uf}` }}
          </router-link>
          <strong v-if="cities.length === 1">
            {{ city.name === 'Todas' ? city.name : `${city.name}-${city.uf}` }}
          </strong>
        </span>
      </li>
    </ul>
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Notifications Dropdown Menu -->
      <li class="nav-item dropdown user-menu">
        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
          <img
            src="/images/no-avatar.jpg"
            class="user-image img-circle elevation-2"
            alt="User Image"
          />
          <span class="d-none d-md-inline">{{ user.name }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <li class="user-header h-auto">
            <p>
              {{ user.name }}

              <small>Administrador</small>

              <small>
                Cidade:
                <router-link :to="{ name: 'select.franchisee' }" v-if="cities.length > 1">
                  {{ city.name }}-{{ city.uf }}
                </router-link>
                <span v-if="cities.length === 1">{{ city.name }}-{{ city.uf }}</span>
              </small>
            </p>
          </li>
          <li class="user-footer">
            <a href="#" @click.prevent="handleLogout" class="btn btn-default btn-flat float-right">
              Sair
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>
