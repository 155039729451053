import Vue from 'vue';
import numeral from 'numeral';

require('numeral/locales/pt-br');

export default Vue.filter('money', value => {
  const locale = 'pt-BR';
  numeral.locale(locale);
  return numeral(parseFloat(value)).format('$ 0,0.00');
});
