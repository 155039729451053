import * as types from './mutations-types';

export default {
  [types.SET_USER](state, user) {
    state.user = user;
  },

  [types.SET_TOKEN](state, token) {
    state.token = token;
  },

  [types.SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions;
  },

  [types.SET_CITIES](state, cities) {
    state.cities = cities;
  },

  [types.SET_FRANCHISEE](state, franchisee) {
    state.franchisee = { ...state.franchisee, ...franchisee };
  },

  [types.SET_CITY](state, city) {
    state.city = { ...state.city, ...city };
  },

  [types.SET_PLANS](state, plans) {
    state.plans = plans;
  },

  [types.ADD_PLAN](state, payload) {
    state.plans.push(payload);
  },

  [types.UPDATE_PLAN](state, payload) {
    state.plans = state.plans.map(plan => {
      if (plan.id === payload.id) {
        return payload;
      }
      return plan;
    });
  },

  [types.REMOVE_PLAN](state, payload) {
    state.plans = state.plans.filter(p => p.id !== payload.id);
  },
};
