<script>
import { getStatusInvoice } from '~/shared/utils';

export default {
  props: {
    status: { type: String, required: true },
  },

  methods: {
    getStatusInvoice,
  },
};
</script>

<template>
  <span v-html="getStatusInvoice(status)"></span>
</template>
