<script>
export default {
  name: 'Pagination',
  // https://vuejs.org/v2/guide/components.html#Prop-Validation
  props: {
    paginationData: Object(),
    currentPage: {
      type: Number,
      default: 1,
    },
    maxItems: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      pageNow: this.currentPage,
    };
  },
  watch: {
    currentPage(val) {
      this.pageNow = val;
    },
  },
  mounted() {},
  computed: {
    hasData() {
      return this.paginationData.total !== undefined;
    },
    pages() {
      /**
       * The generatePagesArray method will calculate
       * the need of ellipsis (...) in case of a super
       * big array of records
       */
      return this.generatePagesArray(
        this.currentPage,
        this.paginationData.total,
        this.paginationData.per_page,
        parseInt(this.maxItems, 10),
      );
    },
    isLast() {
      return this.currentPage === this.paginationData.last_page;
    },
    isFirst() {
      return this.currentPage === 1;
    },
    isOutOfRange() {
      /**
       * ?page= not presente in the URL or present but empty
       */
      if (isNaN(this.currentPage) || this.currentPage === null) {
        return true;
      }
      /**
       * ?page=0 or higher than the total number of pages
       */
      return this.currentPage > this.paginationData.total_pages || this.currentPage < 1;
    },
    currentRange() {
      let firstItem = this.paginationData.per_page * (this.currentPage - 1) + 1;
      let lastItem = this.paginationData.per_page * this.currentPage;
      if (this.paginationData.total < lastItem) {
        lastItem = this.paginationData.total;
      }
      if (this.totalPages === 1) {
        firstItem = 1;
      }
      if (this.totalPages === 0 || this.paginationData.total === 0) {
        firstItem = 0;
      }
      return `${isNaN(firstItem) ? 0 : firstItem}-${isNaN(lastItem) ? 0 : lastItem}`;
    },
  },
  methods: {
    enableKeyboardNavigation() {
      window.jQuery('body').on('keyup', ({ keyCode }) => {
        if (keyCode === 37) {
          this.navigatePrevious();
        }
        if (keyCode === 39) {
          this.navigateNext();
        }
      });
    },
    navigate(page) {
      if (page !== this.currentPage) {
        this.dispatch(page);
      }
    },
    navigatePrevious() {
      if (!this.isFirst) {
        this.dispatch(this.currentPage - 1);
      }
    },
    navigateNext() {
      if (!this.isLast) {
        this.dispatch(this.currentPage + 1);
      }
    },
    dispatch(page) {
      if (page <= this.paginationData.last_page && page >= 1) {
        this.pageNow = page;
        this.$emit('navigate', { page });
      }
    },
    generatePagesArray(currentPage, collectionLength, rowsPerPage, maxItems) {
      if (this.isOutOfRange) {
        this.navigate(1);
        return false;
      }
      const pages = [];
      const totalPages = Math.ceil(collectionLength / rowsPerPage);
      const halfWay = Math.ceil(maxItems / 2);
      const ellipsesNeeded = maxItems < totalPages;
      let position;
      if (currentPage <= halfWay) {
        position = 'start';
      } else if (totalPages - halfWay < currentPage) {
        position = 'end';
      } else {
        position = 'middle';
      }
      let i = 1;
      while (i <= totalPages && i <= maxItems) {
        const pageNumber = this.calculatePageNumber(i, currentPage, maxItems, totalPages);
        const openingEllipsesNeeded = i === 2 && (position === 'middle' || position === 'end');
        const closingEllipsesNeeded =
          i === maxItems - 1 && (position === 'middle' || position === 'start');
        if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
          pages.push('...');
        } else {
          pages.push(pageNumber);
        }
        i += 1;
      }
      return pages;
    },
    calculatePageNumber(i, currentPage, maxItems, totalPages) {
      const halfWay = Math.ceil(maxItems / 2);
      if (i === maxItems) {
        return totalPages;
      }
      if (i === 1) {
        return i;
      }
      if (maxItems < totalPages) {
        if (totalPages - halfWay < currentPage) {
          return totalPages - maxItems + i;
        }
        if (halfWay < currentPage) {
          return currentPage - halfWay + i;
        }
        return i;
      }
      return i;
    },
  },
};
</script>

<template>
  <div>
    <div role="status" aria-live="polite">
      Exibindo {{ currentRange || 0 }} de {{ paginationData.total || 0 }}
      {{ paginationData.total === 1 ? 'registro' : 'registros' }}.
    </div>

    <nav>
      <ul class="pagination justify-content-end mb-0">
        <li class="paginate_button page-item first" :class="{ disabled: isFirst }">
          <a href="#" @click.prevent="navigatePrevious()" class="page-link">
            <ChevronLeftIcon :size="17" />
          </a>
        </li>
        <li
          class="paginate_button page-item"
          :class="{ active: currentPage === page }"
          v-for="(page, index) in pages"
          :key="index"
        >
          <a href="#" class="page-link" v-if="page === '...'">{{ page }}</a>

          <input
            v-model="pageNow"
            type="tel"
            v-tooltip="'Digite o número da página e pressione enter'"
            class="page-link"
            @keyup.enter="navigate(pageNow)"
            style="cursor: text; max-width: 52px; width: auto; text-align: center"
            v-if="page !== '...' && currentPage === page"
          />

          <a
            href="#"
            class="page-link"
            v-if="page !== '...' && currentPage !== page"
            @click.prevent="navigate(page)"
            >{{ page }}</a
          >
        </li>
        <li class="paginate_button page-item last" :class="{ disabled: isLast }">
          <a href="#" class="page-link" @click.prevent="navigateNext()">
            <ChevronRightIcon :size="17" />
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<style scoped></style>
