var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"info"},[_c('label',{staticClass:"form-control-label",domProps:{"innerHTML":_vm._s(_vm.label)}}),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v(" *")]):_vm._e(),_vm._v("   "),(_vm.showInfo)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.showInfo),expression:"showInfo"}]},[_c('InformationIcon',{staticClass:"text-info",attrs:{"size":16}})],1):_vm._e()]),_c('input',{ref:"input",attrs:{"accept":_vm.accept,"type":"file"},on:{"change":_vm.handleFileChange}}),_c('div',{staticClass:"area-upload",class:{
      square: _vm.square,
      'is-invalid': _vm.isInvalid,
    },style:(`max-width: ${_vm.maxWidth ? _vm.maxWidth : 'auto'}; max-height: 250px`)},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(`Clique para ${_vm.placeholder.toLowerCase()}`),expression:"`Clique para ${placeholder.toLowerCase()}`"}],staticClass:"area-clickable",class:{
        'exist-preview': _vm.imgPreview,
        'is-invalid': _vm.isInvalid,
      },on:{"click":function($event){return _vm.$refs.input.click()}}},[(!!_vm.imgPreview)?_c('div',{staticClass:"image-preview",class:{ square: _vm.square }},[_c('img',{ref:"img",staticClass:"preview-img",class:{ square: _vm.square },attrs:{"src":_vm.imgPreview}})]):_vm._e(),_c('div',{staticClass:"placeholder",class:{ 'span-hover': !!_vm.imgPreview }},[_vm._v(_vm._s(_vm.placeholder))])]),(!!_vm.imgPreview && _vm.showRemove)?_c('bora-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.showRemove),expression:"showRemove"}],staticClass:"btn-danger btn-sm btn-close",on:{"click":_vm.removeFile}},[_vm._v(" X ")]):_vm._e()],1),_vm._l((Object.keys(_vm.textValidations)),function(error,index){return _c('div',{key:index},[(_vm.v ? _vm.isDanger && !_vm.vIsEmpty && !_vm.v[error] : false)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" × "+_vm._s(_vm.textValidations[error])+" ")]):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }