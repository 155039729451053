export const initialState = () => ({
  loading: false,
  query: {
    search: '',
    page: 1,
  },
  data: {
    coupons: { total: 0, data: [] },
    total: 0,
  },
});

export default {
  ...initialState(),
};
