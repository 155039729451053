import Vue from 'vue';
import Router from 'vue-router';
import beforeEach from './before-each';
import routes from './routes';

require('./interceptors');

Vue.use(Router);

const router = new Router({
  routes,
  linkActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
});

router.beforeEach(beforeEach);

export default router;
