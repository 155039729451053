export default by => {
  switch (by) {
    case 'user':
      return 'usuário';
    case 'company':
      return 'empresa';
    case 'franchisee':
      return 'licenciado';
    case 'system':
      return 'sistema';
    case 'licensed':
      return 'licenciado';
    default:
      return 'desconhecido';
  }
};
