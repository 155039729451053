import show from './alert';

const install = Vue => {
  Object.defineProperty(Vue.prototype, '$alert', {
    get() {
      return show(this);
    },
  });
};

export default { install };
