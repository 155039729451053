const Index = () => import('./components/index.vue');

export default [
  {
    path: '/reports',
    name: 'reports.index',
    meta: {
      title: 'Relatórios',
    },
    component: Index,
  },
];
