<script>
export default {
  name: 'ErrorsDiv',

  props: {
    name: { type: String, required: true },
    v: { type: Object, required: true },
    textValidations: { type: Object, required: true },
  },
};
</script>

<template>
  <div class="was-validated" v-if="v.form[name].$dirty && v.form[name].$invalid">
    <div
      class="invalid-feedback"
      :key="index"
      v-for="(error, index) in Object.keys(textValidations)"
    >
      &times; {{ textValidations[error] }}
    </div>
  </div>
</template>

<style scoped>
.invalid-feedback {
  text-align: left;
  display: block;
}
</style>
