const Index = () => import('./components/index.vue');

export default [
  {
    path: '/configuracoes',
    name: 'settings.index',
    meta: {
      title: 'Configurações',
    },
    component: Index,
  },
];
