import http from '~/http';

const TYPES = {
  SET_DATA: 'users/setData',
  SET_QUERY: 'users/setQuery',
  SET_LOADING: 'users/setLoading',
};

const state = {
  query: {},
  loading: false,
  list: [],
  pagination: {},
};

const mutations = {
  [TYPES.SET_DATA](st, payload) {
    st.list = payload.data;
    const pagination = payload;
    delete pagination.data;
    st.pagination = pagination;
  },

  [TYPES.SET_QUERY](st, payload) {
    st.query = payload;
  },

  [TYPES.SET_LOADING](st, payload) {
    st.loading = payload;
  },
};

const actions = {
  loadData({ commit, state }) {
    commit(TYPES.SET_LOADING, true);
    http.get('users', { params: state.query }).then(({ data }) => {
      commit(TYPES.SET_DATA, data.data);
      commit(TYPES.SET_LOADING, false);
    });
  },

  setQuery({ commit, dispatch }, payload) {
    commit(TYPES.SET_QUERY, payload);
    dispatch('loadData');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
