export default function (status) {
  switch (status) {
    case 'PENDING':
      return '<span class="badge ml-2 badge-warning">pendente</span>';
    case 'APPROVED':
      return '<span class="badge ml-2 badge-success">aprovado</span>';
    case 'CANCELED':
      return '<span class="badge ml-2 badge-danger">cancelado</span>';
    default:
      return '<span class="badge ml-2 badge-default">desconhecido</span>';
  }
}
