const Index = () => import('./components/index.vue');
const Show = () => import('./components/show');

export default [
  {
    path: '/faturas',
    name: 'invoices.index',
    meta: {
      title: 'Faturas',
    },
    component: Index,
  },
  {
    path: '/faturas/:id',
    name: 'invoices.show',
    meta: {
      title: 'Ver fatura',
    },
    component: Show,
  },
];
