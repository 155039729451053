import Vue from 'vue';
import Button from './Button.vue';
import Loading from './Loading.vue';
import Pagination from './Pagination.vue';
import DateTimeRange from './DateTimeRange.vue';
import DateTime from './DateTime.vue';
import Input from './Input.vue';
import NewInput from '@bora-delivery/shared/components/Input';
import InputFile from './InputFile.vue';
import Select2 from './Select2.vue';
import Select2Ajax from './Select2Ajax.vue';
import StatusInvoice from './StatusInvoice.vue';
import Timeago from './Timeago.vue';
import Modal from '@bora-delivery/shared/components/Modal';
import errorsDiv from './errorsDiv.vue';
import Table from './Table.vue';
import Checkbox from '@bora-delivery/shared/components/Checkbox';
import CheckboxCustom from '@bora-delivery/shared/components/CheckboxCustom';
import File from '@bora-delivery/shared/components/File';
import InputPrecognition from '@bora-delivery/shared/components/InputPrecognition';

Vue.component('BoraButton', Button);
Vue.component('BoraLoading', Loading);
Vue.component('BoraPagination', Pagination);
Vue.component('BoraDateTimeRange', DateTimeRange);
Vue.component('BoraDateTime', DateTime);
Vue.component('BoraInput', Input);
Vue.component('BoraInputFile', InputFile);
Vue.component('BoraSelect2', Select2);
Vue.component('BoraSelect2Ajax', Select2Ajax);
Vue.component('BoraStatusInvoice', StatusInvoice);
Vue.component('BoraTimeago', Timeago);
Vue.component('BoraModal', Modal);
Vue.component('BoraErrorsDiv', errorsDiv);
Vue.component('BoraTable', Table);
Vue.component('BoraCheckbox', Checkbox);
Vue.component('BoraCheckboxCustom', CheckboxCustom);
Vue.component('BoraFile', File);
Vue.component('BoraNewInput', NewInput);
Vue.component('BoraInputPrecognition', InputPrecognition);
