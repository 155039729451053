const getStatusBadge = status => {
  switch (status) {
    case 'PENDING':
      status =
        '<span class="badge badge-warning"><i class="fa fa-spinner"></i> aguardando pagamento</span>';
      break;
    case 'RECEIVED':
      status =
        '<span class="badge badge-success"><i class="fa fa-credit-card"></i> recebido</span>';
      break;
    case 'CONFIRMED':
      status = '<span class="badge badge-success">pagamento confirmado</span>';
      break;
    case 'REFUNDED':
      status = '<span class="badge badge-warning"><i class="fa fa-undo"></i>estornado</span>';
      break;
    case 'CANCELED':
      status = '<span class="badge badge-danger"><i class="fa fa-times"></i> cancelado</span>';
      break;
    case 'OVERDUE':
      status =
        '<span class="badge badge-danger"><i class="fa fa-calendar-times"></i> vencido</span>';
      break;
    case 'RECEIVED_IN_CASH':
      status =
        '<span class="badge badge-success"><i class="fa fa-money-bill"></i> recebido em dinheiro</span>';
      break;
    default:
      status =
        '<span class="badge badge-default"><i class="fa fa-question"></i> desconhecido</span>';
  }

  return status;
};

export default getStatusBadge;
