export const localStorageSetItem = (label, obj) => {
  window.localStorage.setItem(`@${process.env.VUE_APP_SLUG}:${label}`, JSON.stringify(obj));
};

export const localStorageGetItem = label => {
  const string = window.localStorage.getItem(`@${process.env.VUE_APP_SLUG}:${label}`);
  if (string === 'undefined') {
    return null;
  }
  return string ? JSON.parse(string) : '';
};

export const localStorageRemoveItem = label => {
  localStorage.removeItem(`@${process.env.VUE_APP_SLUG}:${label}`);
  return true;
};
