export default [
  { value: 'America/Rio_branco', label: 'Acre' },
  { value: 'America/Maceio', label: 'Alagoas, Sergipe' },
  { value: 'America/Belem', label: 'Amapá, Pará' },
  { value: 'America/Manaus', label: 'Amazonas' },
  { value: 'America/Bahia', label: 'Bahia' },
  { value: 'America/Fortaleza', label: 'Ceará, Piauí e Rio Grande do Norte' },
  {
    value: 'America/Sao_Paulo',
    label:
      'Distrito Federal, Espírito Santo, Minas Gerais, Paraná, Rio de Janeiro, Rio Grande do Sul, Santa Catarina, São Paulo',
  },
  { value: 'America/Araguaina', label: 'Goiás, Maranhão' },
  { value: 'America/Cuiaba', label: 'Mato Grosso' },
  { value: 'America/Campo_Grande', label: 'Mato Grosso do Sul' },
  { value: 'America/Recife', label: 'Paraíba, Pernambuco' },
  { value: 'America/Porto_Velho', label: 'Rondônia' },
  { value: 'America/Boa_Vista', label: 'Roraima' },
  { value: 'America/Araguaia', label: 'Tocantins' },
];
