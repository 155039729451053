export default ({
  title,
  message,
  callback,
  confirmButtonText = 'Sim',
  cancelButtonText = 'Cancelar',
  showLoaderOnConfirm = true,
  showCancelButton = true,
  icon = null,
}) => {
  return window.swal.fire({
    title,
    html: message,
    showCancelButton,
    confirmButtonText,
    confirmButtonColor: '#328af1',
    cancelButtonText,
    preConfirm: callback,
    showLoaderOnConfirm,
    icon,
  });
};
