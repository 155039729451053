import store from '~/store';
import { localStorageGetItem } from '~/shared/utils/local-storage';
import checkPermission from '~/shared/acl/check';

const isAuthRoute = route => route.path.indexOf('/login') !== -1;
const isAuthPassword = route => route.path.indexOf('/recuperar-senha') !== -1;
const isRouteSetCompany = route => route.path.indexOf('/selecionar-cidade') !== -1;
const isLogged = () => store.getters.isLogged;

export default async (to, from, next) => {
  const { title } = to.meta;

  document.title = `${process.env.VUE_APP_NAME} ${title ? ` - ${title}` : ''}`;

  if (localStorageGetItem('token')) {
    if (!store.getters.isLogged) {
      await store.dispatch('setDataFromLocalStorage');
    }

    if (store.getters.isLogged && !store.getters.isSigning) {
      const { permissions, city } = store.getters;

      if (to.meta.hideInAllFranchisees && city?.id === 'all') {
        next(false);
        return;
      }

      if (to.meta.shield) {
        if (checkPermission(to.meta.shield, permissions)) {
          next();
        } else if (from.path === '/') {
          next({
            name: 'dashboard',
          });
        }
      }
    }

    if (store.getters.isSigning && !isRouteSetCompany(to)) {
      next({
        name: 'select.franchisee',
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }

    if ((isAuthRoute(to) && isLogged()) || (isAuthPassword(to) && isLogged())) {
      next({
        name: 'dashboard',
      });
    }
  } else if (!isAuthRoute(to) && !isLogged() && !isAuthPassword(to)) {
    next({
      name: 'login',
      query: {
        redirect: to.fullPath,
      },
    });
  } else {
    next();
  }
};
