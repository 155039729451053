import * as types from './mutations-types';

export const setCategories = (context, payload) => {
  context.commit(types.SET_CATEGORIES, payload);
};

export const createUpdateCategory = (context, payload) => {
  context.commit(types.CREATE_UPDATE_CATEGORY, payload);
};

export const removeCategory = (context, payload) => {
  context.commit(types.REMOVE_CATEGORY, payload);
};

// Products

export const createUpdateProduct = (context, { category, product }) => {
  context.commit(types.CREATE_UPDATE_PRODUCT, { category, product });
};

export const removeProduct = (context, { category, product }) => {
  context.commit(types.REMOVE_PRODUCT, { category, product });
};
