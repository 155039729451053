import iziToast from 'izitoast';

import 'izitoast/dist/css/iziToast.css';

const show = () => ({
  show(title, message, type, icon = null, image = null) {
    if (type === 'show') {
      iziToast.show({
        id: 'haduken',
        theme: 'dark',
        icon,
        title,
        message,
        position: 'topCenter',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        progressBarColor: 'rgb(0, 255, 184)',
        image,
        imageWidth: 70,
        layout: 2,
        onClosing() {
          console.info('onClosing');
        },
        onClosed(instance, toast, closedBy) {
          console.info(`Closed | closedBy: ${closedBy}`);
        },
      });
    } else {
      iziToast[type]({
        id: 'success',
        title,
        message,
        position: 'topRight',
        transitionIn: 'bounceInLeft',
      });
    }
  },
});

export default show;
