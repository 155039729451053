<script>
export default {
  name: 'Table',

  props: {
    showDelete: { type: Boolean, default: true },
    isDelectable: { type: Function, default: () => true },
    refs: {},
    delete: {
      type: Function,
      default: () => {},
    },
    routeEdit: { type: String, default: '' },
    showEdit: { type: Boolean, default: true },
    isEditable: { type: Function, default: () => true },
    index: { type: Function, required: true },
    table: { type: Array, required: true },
    id: { type: String, default: 'id' },
    buttons: { type: Array, default: () => [] },
    showFooter: { type: Boolean, default: true },
    customParams: { type: Object, default: () => ({}) },
    orderBy: { type: String, default: 'created_at' },
    orderDirection: { type: String, default: 'desc' },
    classTable: { type: String, default: '' },
  },

  data() {
    return {
      items: {},
      loading: true,
      params: {
        page: 1,
        decreasing: this.orderDirection === 'desc',
        orderBy: this.orderBy,
      },
    };
  },

  computed: {
    tableIsEmpty() {
      return !this.loading && this.items.total === 0;
    },
  },

  watch: {
    loading(val) {
      this.$emit('loading', val);
    },
  },

  methods: {
    load() {
      this.loading = true;
      const params = {
        ...this.params,
        ...this.customParams,
      };

      this.index(params)
        .then(({ data }) => {
          this.items = data.data;
          this.$emit('total', this.items.total);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    search() {
      this.params.page = 1;
      this.load();
    },

    navigate({ page }) {
      this.params.page = page;
      this.load();
    },

    sortBy(field) {
      if (this.tableIsEmpty) {
        return;
      }

      if (field) {
        if (this.params.orderBy !== field[0]) {
          this.params.decreasing = true;
        } else {
          this.params.decreasing = !this.params.decreasing;
        }
        this.params.orderBy = field[0];
      }
      this.load();
    },

    clickButton(callback, item) {
      callback(item, this);
    },

    // Execute callback in link.callback
    linkCallback(item, tb) {
      tb.link.callback(item, this, this.refs);
    },

    getParamsRoute(item, tb) {
      const route = {};
      route[this.id] = item[tb.link.id || this.id];

      return route;
    },

    getIndices(item, table) {
      if (table.customField) {
        return table.customField(item, this.$options.filters);
      }

      if (typeof table.field === 'object') {
        let value = item;
        table.field.forEach(tb => {
          value = value[tb];
        });
        if (table.mask) {
          return this.$options.filters[table.mask](value);
        }
        return value;
      }
    },

    getImage(item, table) {
      let value = item;
      table.img.src.forEach(tb => {
        value = value[tb];
      });
      return value;
    },

    removeItem(item) {
      this.delete(item);
    },
  },

  mounted() {
    this.sortBy();
  },
};
</script>

<template>
  <div class="table-responsive">
    <table class="table table-striped table-hover" :class="classTable">
      <thead>
        <th
          v-for="tb in table"
          :key="tb.name"
          @click="tb.sortable ? sortBy(tb.field) : ''"
          :class="{ pointer: tb.sortable }"
        >
          {{ tb.title }}
          <i
            class="fa fa-sort-up"
            v-if="tb.sortable && tb.name === params.orderBy && !params.decreasing"
          ></i>
          <i
            class="fa fa-sort-down"
            v-if="tb.sortable && tb.name === params.orderBy && params.decreasing"
          ></i>
          <i v-if="tb.sortable && tb.name !== params.orderBy" class="fa fa-sort"></i>
        </th>
        <th class="text-right" v-if="showDelete || showEdit || buttons.length">Ações</th>
      </thead>

      <tbody v-if="tableIsEmpty">
        <tr>
          <td :colspan="table.length + 1">
            <div class="alert alert-info">Nenhum item encontrado.</div>
          </td>
        </tr>
      </tbody>

      <tbody v-if="loading">
        <tr>
          <td :colspan="table.length + 1"><bora-loading :loading="true" :showMessage="true" /></td>
        </tr>
      </tbody>

      <tbody>
        <tr v-for="item in items.data" :key="item.id">
          <td v-for="tb in table" :key="tb.name">
            <router-link
              v-tooltip="tb.link ? tb.link.title : ''"
              v-if="tb.link && tb.link.route"
              :to="{ name: tb.link.route, params: getParamsRoute(item, tb) }"
            >
              <span v-if="tb.customField" v-html="getIndices(item, tb)"></span>
              <span v-else>
                <img
                  :src="getImage(item, tb)"
                  :class="tb.img.class || 'img-circle'"
                  :width="tb.img.width || 32"
                  v-if="tb.img"
                  alt=""
                />
                {{ getIndices(item, tb) }}
              </span>
            </router-link>

            <a
              v-tooltip="tb.link ? tb.link.title : ''"
              v-else-if="tb.link && tb.link.url"
              :href="tb.link.url"
            >
              <span v-if="tb.customField" v-html="getIndices(item, tb)"></span>
              <span v-else>
                <img
                  :src="getImage(item, tb)"
                  :class="tb.img.class || 'img-circle'"
                  :width="tb.img.width || 32"
                  v-if="tb.img"
                  alt=""
                />
                {{ getIndices(item, tb) }}
              </span>
            </a>

            <a
              v-tooltip="tb.link ? tb.link.title : ''"
              v-else-if="tb.link && tb.link.callback"
              href="#"
              @click.prevent="linkCallback(item, tb)"
            >
              <span v-if="tb.customField" v-html="getIndices(item, tb)"></span>
              <span v-else>
                <img
                  :src="getImage(item, tb)"
                  :class="tb.img.class || 'img-circle'"
                  :width="tb.img.width || 32"
                  v-if="tb.img"
                  alt=""
                />
                {{ getIndices(item, tb) }}
              </span>
            </a>

            <span v-else>
              <span v-if="tb.customField" v-html="getIndices(item, tb)"></span>
              <span v-else>
                <img
                  :src="getImage(item, tb)"
                  :class="tb.img.class || 'img-circle'"
                  :width="tb.img.width || 32"
                  v-if="tb.img"
                  alt=""
                />
                {{ getIndices(item, tb) }}
              </span>
            </span>
          </td>

          <td class="text-right" v-if="showEdit || showDelete || buttons.length">
            <span v-for="button in buttons" :key="button.name">
              <span v-if="button.isVisible(item)">
                <router-link
                  :class="button.class"
                  v-tooltip="button.icon ? button.title : ''"
                  :to="{ name: button.route, params: getParamsRoute(item, tb) }"
                  v-if="button.route"
                >
                  <i :class="button.icon" v-if="button.icon"></i>
                  <span v-if="!button.icon">{{ button.title }}</span>
                </router-link>

                <span v-if="buttons.length">&nbsp;</span>

                <button
                  :class="button.class ? button.class : 'btn-default'"
                  class="btn btn-sm"
                  v-tooltip="button.icon ? button.title : ''"
                  @click="clickButton(button.callback, item)"
                  v-if="!button.route"
                >
                  <i :class="button.icon" v-if="button.icon"></i>
                  <span v-if="!button.icon">{{ button.title }}</span>
                </button>
                <span v-if="buttons.length">&nbsp;</span>
              </span>
            </span>

            <span v-if="showEdit">
              <router-link
                v-if="isEditable(item)"
                v-tooltip="'Editar'"
                class="btn btn-info btn-xs"
                :to="{ name: routeEdit, params: getParamsRoute(item, tb) }"
              >
                <i class="fa fa-pen"></i>
              </router-link>
              {{ ' ' }}
            </span>
            <span v-if="showDelete">
              <button
                @click.prevent="removeItem(item)"
                v-if="isDelectable(item)"
                type="button"
                rel="tooltip"
                v-tooltip="'Remover'"
                class="btn btn-round btn-danger btn-icon btn-xs"
              >
                <i class="fa fa-trash"></i>
              </button>
            </span>
          </td>
        </tr>
      </tbody>

      <tfoot v-if="showFooter">
        <th
          v-for="tb in table"
          :key="tb.name"
          @click="tb.sortable ? sortBy(tb.field) : ''"
          :class="{ pointer: tb.sortable }"
        >
          {{ tb.title }}
          <i
            class="fa fa-sort-up"
            v-if="tb.sortable && tb.name === params.orderBy && !params.decreasing"
          ></i>
          <i
            class="fa fa-sort-down"
            v-if="tb.sortable && tb.name === params.orderBy && params.decreasing"
          ></i>
          <i v-if="tb.sortable && tb.name !== params.orderBy" class="fa fa-sort"></i>
        </th>
        <th class="text-right" v-if="showDelete || showEdit || buttons.length">Ações</th>
      </tfoot>
    </table>

    <bora-pagination
      @navigate="navigate"
      :paginationData="items"
      :currentPage="items.current_page"
    />
  </div>
</template>

<style scoped>
.pointer {
  cursor: pointer !important;
}
.table td {
  vertical-align: middle;
}
</style>
