<script>
import { mapGetters, mapActions } from 'vuex';
import http from '~/http';

export default {
  name: 'BoraDeliveryFranchisees',

  computed: {
    ...mapGetters(['isLogged', 'user', 'franchisee', 'city']),

    layout() {
      return `${this.$route.meta.layout || 'app'}-layout`;
    },
  },

  watch: {
    franchisee() {
      window.timezone = this.franchisee.timezone || this.user.timezone;
    },
  },

  methods: {
    ...mapActions(['updateDataAuth']),
  },

  mounted() {
    if (this.isLogged) {
      http
        .get('auth/account')
        .then(({ data }) => {
          this.updateDataAuth(data.data);
        })
        .catch(() => {
          this.$alert.show(
            'Ops',
            'Houve um erro ao carregar suas informações. Tente novamente.',
            'error',
          );
        });
    }
  },
};
</script>

<template>
  <component :is="layout">
    <slide-y-up-transition mode="out-in" origin="center top">
      <router-view />
    </slide-y-up-transition>
  </component>
</template>
