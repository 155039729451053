// import 'admin-lte/build/scss/AdminLTE.scss';
import './css/core.css';
import './css/app.css';

window.jQuery = require('jquery');

require('bootstrap');
require('admin-lte');
require('select2');
require('select2/dist/js/i18n/pt-BR');
require('./js/app');

window.swal = require('sweetalert2');

window.$ = window.jQuery;
