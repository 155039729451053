const Plans = () => import('./index.vue');
const CreateEdit = () => import('./components/create-edit.vue');

export default [
  {
    path: '/planos',
    name: 'plans.index',
    meta: {
      title: 'Planos',
    },
    component: Plans,
  },
  {
    path: '/planos/novo',
    name: 'plans.create',
    meta: {
      title: 'Criar plano',
      hideInAllFranchisees: true,
    },
    component: CreateEdit,
  },
  {
    path: '/planos/editar/:id',
    name: 'plans.edit',
    meta: {
      title: 'Editar plano',
      hideInAllFranchisees: true,
    },
    component: CreateEdit,
  },
];
