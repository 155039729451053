export default [
  { id: '100', name: '100 - PLANNER CV S.A.' },
  { id: '101', name: '101 - RENASCENCA DTVM LTDA' },
  { id: '102', name: '102 - XP INVESTIMENTOS CCTVM S/A' },
  { id: '104', name: '104 - CAIXA ECONOMICA FEDERAL' },
  { id: '105', name: '105 - LECCA CFI S.A.' },
  { id: '107', name: '107 - BCO BOCOM BBM S.A.' },
  { id: '108', name: '108 - PORTOCRED S.A. - CFI' },
  { id: '111', name: '111 - OLIVEIRA TRUST DTVM S.A.' },
  { id: '113', name: '113 - MAGLIANO S.A. CCVM' },
  { id: '114', name: '114 - CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO' },
  { id: '117', name: '117 - ADVANCED CC LTDA' },
  { id: '118', name: '118 - STANDARD CHARTERED BI S.A.' },
  { id: '119', name: '119 - BCO WESTERN UNION' },
  { id: '120', name: '120 - BCO RODOBENS S.A.' },
  { id: '121', name: '121 - BCO AGIBANK S.A.' },
  { id: '122', name: '122 - BCO BRADESCO BERJ S.A.' },
  { id: '124', name: '124 - BCO WOORI BANK DO BRASIL S.A.' },
  { id: '125', name: '125 - PLURAL BCO BM' },
  { id: '126', name: '126 - BR PARTNERS BI' },
  { id: '127', name: '127 - CODEPE CVC S.A.' },
  { id: '128', name: '128 - MS BANK S.A. BCO DE CÂMBIO' },
  { id: '129', name: '129 - UBS BRASIL BI S.A.' },
  { id: '130', name: '130 - CARUANA SCFI' },
  { id: '131', name: '131 - TULLETT PREBON BRASIL CVC LTDA' },
  { id: '132', name: '132 - ICBC DO BRASIL BM S.A.' },
  { id: '133', name: '133 - CRESOL CONFEDERAÇÃO' },
  { id: '134', name: '134 - BGC LIQUIDEZ DTVM LTDA' },
  { id: '136', name: '136 - UNICRED' },
  { id: '138', name: '138 - GET MONEY CC LTDA' },
  { id: '139', name: '139 - INTESA SANPAOLO BRASIL S.A. BM' },
  { id: '140', name: '140 - EASYNVEST - TÍTULO CV SA' },
  { id: '142', name: '142 - BROKER BRASIL CC LTDA.' },
  { id: '143', name: '143 - TREVISO CC S.A.' },
  { id: '144', name: '144 - BEXS BCO DE CAMBIO S.A.' },
  { id: '145', name: '145 - LEVYCAM CCV LTDA' },
  { id: '146', name: '146 - GUITTA CC LTDA' },
  { id: '149', name: '149 - FACTA S.A. CFI' },
  { id: '157', name: '157 - ICAP DO BRASIL CTVM LTDA.' },
  { id: '159', name: '159 - CASA CREDITO S.A. SCM' },
  { id: '163', name: '163 - COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO' },
  { id: '169', name: '169 - BCO OLÉ BONSUCESSO CONSIGNADO S.A.' },
  { id: '173', name: '173 - BRL TRUST DTVM SA' },
  { id: '174', name: '174 - PERNAMBUCANAS FINANC S.A. CFI' },
  { id: '177', name: '177 - GUIDE' },
  { id: '180', name: '180 - CM CAPITAL MARKETS CCTVM LTDA' },
  { id: '182', name: '182 - DACASA FINANCEIRA S/A - SCFI' },
  { id: '183', name: '183 - SOCRED SA - SCMEPP' },
  { id: '184', name: '184 - BCO ITAÚ BBA S.A.' },
  { id: '188', name: '188 - ATIVA S.A. INVESTIMENTOS CCTVM' },
  { id: '189', name: '189 - HS FINANCEIRA' },
  { id: '190', name: '190 - SERVICOOP' },
  { id: '191', name: '191 - NOVA FUTURA CTVM LTDA.' },
  { id: '194', name: '194 - PARMETAL DTVM LTDA' },
  { id: '196', name: '196 - FAIR CC S.A.' },
  { id: '197', name: '197 - STONE PAGAMENTOS S.A.' },
  { id: '208', name: '208 - BANCO BTG PACTUAL S.A.' },
  { id: '212', name: '212 - BANCO ORIGINAL' },
  { id: '213', name: '213 - BCO ARBI S.A.' },
  { id: '217', name: '217 - BANCO JOHN DEERE S.A.' },
  { id: '218', name: '218 - BCO BS2 S.A.' },
  { id: '222', name: '222 - BCO CRÉDIT AGRICOLE BR S.A.' },
  { id: '224', name: '224 - BCO FIBRA S.A.' },
  { id: '233', name: '233 - BANCO CIFRA' },
  { id: '237', name: '237 - BCO BRADESCO S.A.' },
  { id: '241', name: '241 - BCO CLASSICO S.A.' },
  { id: '243', name: '243 - BCO MÁXIMA S.A.' },
  { id: '246', name: '246 - BCO ABC BRASIL S.A.' },
  { id: '249', name: '249 - BANCO INVESTCRED UNIBANCO S.A.' },
  { id: '250', name: '250 - BCV' },
  { id: '253', name: '253 - BEXS CC S.A.' },
  { id: '254', name: '254 - PARANA BCO S.A.' },
  { id: '260', name: '260 - NU PAGAMENTOS S.A.' },
  { id: '265', name: '265 - BCO FATOR S.A.' },
  { id: '266', name: '266 - BCO CEDULA S.A.' },
  { id: '268', name: '268 - BARI CIA HIPOTECÁRIA' },
  { id: '269', name: '269 - HSBC BANCO DE INVESTIMENTO' },
  { id: '270', name: '270 - SAGITUR CC LTDA' },
  { id: '271', name: '271 - IB CCTVM S.A.' },
  { id: '272', name: '272 - AGK CC S.A.' },
  { id: '273', name: '273 - CCR DE SÃO MIGUEL DO OESTE' },
  { id: '274', name: '274 - MONEY PLUS SCMEPP LTDA' },
  { id: '276', name: '276 - SENFF S.A. - CFI' },
  { id: '278', name: '278 - GENIAL INVESTIMENTOS CVM S.A.' },
  { id: '279', name: '279 - CCR DE PRIMAVERA DO LESTE' },
  { id: '280', name: '280 - AVISTA S.A. CFI' },
  { id: '281', name: '281 - CCR COOPAVEL' },
  { id: '283', name: '283 - RB CAPITAL INVESTIMENTOS DTVM LTDA.' },
  { id: '285', name: '285 - FRENTE CC LTDA.' },
  { id: '286', name: '286 - CCR DE OURO' },
  { id: '288', name: '288 - CAROL DTVM LTDA.' },
  { id: '289', name: '289 - DECYSEO CC LTDA.' },
  { id: '290', name: '290 - PAGSEGURO' },
  { id: '292', name: '292 - BS2 DTVM S.A.' },
  { id: '293', name: '293 - LASTRO RDV DTVM LTDA' },
  { id: '296', name: '296 - VISION S.A. CC' },
  { id: '298', name: '298 - VIPS CC LTDA.' },
  { id: '299', name: '299 - SOROCRED CFI S.A.' },
  { id: '300', name: '300 - BCO LA NACION ARGENTINA' },
  { id: '301', name: '301 - BPP IP S.A.' },
  { id: '306', name: '306 - PORTOPAR DTVM LTDA' },
  { id: '307', name: '307 - TERRA INVESTIMENTOS DTVM' },
  { id: '309', name: '309 - CAMBIONET CC LTDA' },
  { id: '310', name: '310 - VORTX DTVM LTDA.' },
  { id: '315', name: '315 - PI DTVM S.A.' },
  { id: '318', name: '318 - BCO BMG S.A.' },
  { id: '319', name: '319 - OM DTVM LTDA' },
  { id: '320', name: '320 - BCO CCB BRASIL S.A.' },
  { id: '321', name: '321 - CREFAZ SCMEPP LTDA' },
  { id: '322', name: '322 - CCR DE ABELARDO LUZ' },
  { id: '323', name: '323 - MERCADO PAGO' },
  { id: '325', name: '325 - ÓRAMA DTVM S.A.' },
  { id: '329', name: '329 - QI SCD S.A.' },
  { id: '330', name: '330 - BANCO BARI S.A.' },
  { id: '331', name: '331 - FRAM CAPITAL DTVM S.A.' },
  { id: '332', name: '332 - ACESSO' },
  { id: '335', name: '335 - BANCO DIGIO' },
  { id: '336', name: '336 - BCO C6 S.A.' },
  { id: '340', name: '340 - SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A.' },
  { id: '341', name: '341 - ITAÚ UNIBANCO S.A.' },
  { id: '342', name: '342 - CREDITAS SCD' },
  { id: '343', name: '343 - FFA SCMEPP LTDA.' },
  { id: '348', name: '348 - BCO XP S.A.' },
  { id: '349', name: '349 - AMAGGI S.A. CFI' },
  { id: '352', name: '352 - TORO CTVM LTDA' },
  { id: '354', name: '354 - NECTON INVESTIMENTOS S.A CVM' },
  { id: '355', name: '355 - ÓTIMO SCD S.A.' },
  { id: '366', name: '366 - BCO SOCIETE GENERALE BRASIL' },
  { id: '370', name: '370 - BCO MIZUHO S.A.' },
  { id: '376', name: '376 - BCO J.P. MORGAN S.A.' },
  { id: '389', name: '389 - BCO MERCANTIL DO BRASIL S.A.' },
  { id: '394', name: '394 - BCO BRADESCO FINANC. S.A.' },
  { id: '399', name: '399 - KIRTON BANK' },
  { id: '412', name: '412 - BCO CAPITAL S.A.' },
  { id: '422', name: '422 - BCO SAFRA S.A.' },
  { id: '456', name: '456 - BCO MUFG BRASIL S.A.' },
  { id: '464', name: '464 - BCO SUMITOMO MITSUI BRASIL S.A.' },
  { id: '473', name: '473 - BCO CAIXA GERAL BRASIL S.A.' },
  { id: '477', name: '477 - CITIBANK N.A.' },
  { id: '479', name: '479 - BCO ITAUBANK S.A.' },
  { id: '487', name: '487 - DEUTSCHE BANK S.A.BCO ALEMAO' },
  { id: '488', name: '488 - JPMORGAN CHASE BANK' },
  { id: '492', name: '492 - ING BANK N.V.' },
  { id: '495', name: '495 - BCO LA PROVINCIA B AIRES BCE' },
  { id: '505', name: '505 - BCO CREDIT SUISSE S.A.' },
  { id: '545', name: '545 - SENSO CCVM S.A.' },
  { id: '600', name: '600 - BCO LUSO BRASILEIRO S.A.' },
  { id: '604', name: '604 - BCO INDUSTRIAL DO BRASIL S.A.' },
  { id: '610', name: '610 - BCO VR S.A.' },
  { id: '611', name: '611 - BCO PAULISTA S.A.' },
  { id: '612', name: '612 - BCO GUANABARA S.A.' },
  { id: '613', name: '613 - OMNI BANCO S.A.' },
  { id: '623', name: '623 - BANCO PAN' },
  { id: '626', name: '626 - BCO FICSA S.A.' },
  { id: '630', name: '630 - SMARTBANK' },
  { id: '633', name: '633 - BCO RENDIMENTO S.A.' },
  { id: '634', name: '634 - BCO TRIANGULO S.A.' },
  { id: '637', name: '637 - BCO SOFISA S.A.' },
  { id: '643', name: '643 - BCO PINE S.A.' },
  { id: '652', name: '652 - ITAÚ UNIBANCO HOLDING S.A.' },
  { id: '653', name: '653 - BCO INDUSVAL S.A.' },
  { id: '654', name: '654 - BCO A.J. RENNER S.A.' },
  { id: '655', name: '655 - BCO VOTORANTIM S.A.' },
  { id: '707', name: '707 - BCO DAYCOVAL S.A' },
  { id: '712', name: '712 - BCO OURINVEST S.A.' },
  { id: '739', name: '739 - BCO CETELEM S.A.' },
  { id: '741', name: '741 - BCO RIBEIRAO PRETO S.A.' },
  { id: '743', name: '743 - BANCO SEMEAR' },
  { id: '745', name: '745 - BCO CITIBANK S.A.' },
  { id: '746', name: '746 - BCO MODAL S.A.' },
  { id: '747', name: '747 - BCO RABOBANK INTL BRASIL S.A.' },
  { id: '748', name: '748 - BCO COOPERATIVO SICREDI S.A.' },
  { id: '751', name: '751 - SCOTIABANK BRASIL' },
  { id: '752', name: '752 - BCO BNP PARIBAS BRASIL S A' },
  { id: '753', name: '753 - NOVO BCO CONTINENTAL S.A. - BM' },
  { id: '754', name: '754 - BANCO SISTEMA' },
  { id: '755', name: '755 - BOFA MERRILL LYNCH BM S.A.' },
  { id: '756', name: '756 - BANCOOB' },
  { id: '757', name: '757 - BCO KEB HANA DO BRASIL S.A.' },
  { id: '000', name: '000 - Banco não informado' },
  { id: '001', name: '001 - BCO DO BRASIL S.A.' },
  { id: '003', name: '003 - BCO DA AMAZONIA S.A.' },
  { id: '004', name: '004 - BCO DO NORDESTE DO BRASIL S.A.' },
  { id: '007', name: '007 - BNDES' },
  { id: '010', name: '010 - CREDICOAMO' },
  { id: '011', name: '011 - C.SUISSE HEDGING-GRIFFO CV S/A' },
  { id: '012', name: '012 - BANCO INBURSA' },
  { id: '014', name: '014 - STATE STREET BR S.A. BCO COMERCIAL' },
  { id: '015', name: '015 - UBS BRASIL CCTVM S.A.' },
  { id: '016', name: '016 - CCM DESP TRÂNS SC E RS' },
  { id: '017', name: '017 - BNY MELLON BCO S.A.' },
  { id: '018', name: '018 - BCO TRICURY S.A.' },
  { id: '021', name: '021 - BCO BANESTES S.A.' },
  { id: '024', name: '024 - BCO BANDEPE S.A.' },
  { id: '025', name: '025 - BCO ALFA S.A.' },
  { id: '029', name: '029 - BANCO ITAÚ CONSIGNADO S.A.' },
  { id: '033', name: '033 - BCO SANTANDER (BRASIL) S.A.' },
  { id: '036', name: '036 - BCO BBI S.A.' },
  { id: '037', name: '037 - BCO DO EST. DO PA S.A.' },
  { id: '040', name: '040 - BCO CARGILL S.A.' },
  { id: '041', name: '041 - BCO DO ESTADO DO RS S.A.' },
  { id: '047', name: '047 - BCO DO EST. DE SE S.A.' },
  { id: '060', name: '060 - CONFIDENCE CC S.A.' },
  { id: '062', name: '062 - HIPERCARD BM S.A.' },
  { id: '063', name: '063 - BANCO BRADESCARD' },
  { id: '064', name: '064 - GOLDMAN SACHS DO BRASIL BM S.A' },
  { id: '065', name: '065 - BCO ANDBANK S.A.' },
  { id: '066', name: '066 - BCO MORGAN STANLEY S.A.' },
  { id: '069', name: '069 - BCO CREFISA S.A.' },
  { id: '070', name: '070 - BRB - BCO DE BRASILIA S.A.' },
  { id: '074', name: '074 - BCO. J.SAFRA S.A.' },
  { id: '075', name: '075 - BCO ABN AMRO S.A.' },
  { id: '076', name: '076 - BCO KDB BRASIL S.A.' },
  { id: '077', name: '077 - BANCO INTER' },
  { id: '078', name: '078 - HAITONG BI DO BRASIL S.A.' },
  { id: '079', name: '079 - BCO ORIGINAL DO AGRO S/A' },
  { id: '080', name: '080 - B&T CC LTDA.' },
  { id: '081', name: '081 - BANCOSEGURO S.A.' },
  { id: '082', name: '082 - BANCO TOPÁZIO S.A.' },
  { id: '083', name: '083 - BCO DA CHINA BRASIL S.A.' },
  { id: '084', name: '084 - UNIPRIME NORTE DO PARANÁ - CC' },
  { id: '085', name: '085 - COOP CENTRAL AILOS' },
  { id: '089', name: '089 - CCR REG MOGIANA' },
  { id: '091', name: '091 - CCCM UNICRED CENTRAL RS' },
  { id: '092', name: '092 - BRK S.A. CFI' },
  { id: '093', name: '093 - PÓLOCRED SCMEPP LTDA.' },
  { id: '094', name: '094 - BANCO FINAXIS' },
  { id: '095', name: '095 - TRAVELEX BANCO DE CÂMBIO S.A.' },
  { id: '096', name: '096 - BCO B3 S.A.' },
  { id: '097', name: '097 - CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.' },
  { id: '098', name: '098 - CREDIALIANÇA CCR' },
  { id: '099', name: '099 - UNIPRIME CENTRAL CCC LTDA.' },
];
