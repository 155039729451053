import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import * as actions from './actions';

import { store as auth } from '../pages/auth';
import { storeMenu as menu } from '../pages/companies';
// import { store as coupons } from '../pages/coupons';
import { store as users } from '../pages/users';
import { store as orders } from '../pages/orders';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  actions,
  modules: {
    auth,
    menu,
    // coupons,
    users,
    orders,
  },
});
