const Notifications = () => import('./components/index.vue');

export default [
  {
    path: '/notificacoes',
    name: 'notifications',
    meta: {
      title: 'Notifications',
    },
    component: Notifications,
  },
];
