<script>
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { mapGetters } from 'vuex';

window.Pusher = Pusher;

const {
  VUE_APP_WEBSOCKET_APP_KEY,
  VUE_APP_WEBSOCKET_HOST,
  VUE_APP_WEBSOCKET_PORT,
  VUE_APP_WEBSOCKET_AUTH_ENDPOINT,
  VUE_APP_WEBSOCKET_ENCRYPTED,
  VUE_APP_WEBSOCKET_CLUSTER,
} = process.env;

export default {
  name: 'LaravelEcho',

  data() {
    return {
      connected: false,
    };
  },

  computed: {
    ...mapGetters(['city', 'token', 'licensed']),
  },

  methods: {
    connect() {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: VUE_APP_WEBSOCKET_APP_KEY,
        wsHost: VUE_APP_WEBSOCKET_HOST,
        wsPort: VUE_APP_WEBSOCKET_PORT,
        cluster: VUE_APP_WEBSOCKET_CLUSTER,
        encrypted: VUE_APP_WEBSOCKET_ENCRYPTED === 'true',
        forceTLS: false,
        authEndpoint: VUE_APP_WEBSOCKET_AUTH_ENDPOINT,
        enabledTransports: ['ws', 'wss'],
        auth: {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        },
      });
    },

    listen() {
      console.log(this.licensed.id);
      window.Echo.private(`licensed.${this.licensed.id}`).listen(
        '.invoice_received',
        ({ number }) => {
          this.$alert.show(
            'Recebemos seu pagamento!',
            `Fatura #${number} recebida com sucesso!`,
            'show',
            'ico-success',
          );
        },
      );
    },
  },

  mounted() {
    this.connect();

    this.listen();

    window.Echo.connector.pusher.connection.bind('connected', () => {
      console.log('connected');
      this.connected = true;
    });

    window.Echo.connector.pusher.connection.bind('connecting', () => {
      console.log('connecting');
    });

    window.Echo.connector.pusher.connection.bind('unavailable', () => {
      console.log('unavailable');
      this.connected = false;
    });

    window.Echo.connector.pusher.connection.bind('disconnected', () => {
      console.log('disconnected');
      this.connected = false;
    });
  },

  destroyed() {
    this.connected = false;
    if (window.Echo) {
      window.Echo.disconnect();
    }
  },
};
</script>

<template>
  <span></span>
</template>
