<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import { parseISO, format } from 'date-fns';

export default {
  name: 'DateTime',

  components: { VueCtkDateTimePicker },

  props: {
    info: { type: String, default: '' },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    value: { default: null },
    props: { default: null },
    noLabel: { type: Boolean, default: false },
    format: { type: String, default: 'YYYY-MM-DD' },
    formatted: { type: String, default: 'DD/MM/YYYY' },
    minuteInterval: { type: Number, default: 10 },
    noClearButton: { type: Boolean, default: false },
    overlay: { type: Boolean, default: true },
    minDate: { type: String },
    maxDate: { type: String },
    onlyDate: { type: Boolean, default: false },
    autoClose: { type: Boolean, default: false },
    dateFnsFormat: { type: String, default: '' },
  },

  data() {
    return {
      model: null,
    };
  },

  watch: {
    value(val) {
      if (this.dateFnsFormat) {
        this.model = format(parseISO(val), this.dateFnsFormat);
      } else {
        this.model = val;
      }
    },

    model() {
      this.$emit('input', this.model);
    },
  },

  methods: {
    validate() {
      if (this.model) {
        this.$emit('input', this.model);
      }
    },
  },

  mounted() {
    this.model = this.value;
  },
};
</script>

<template>
  <div>
    <label class="justify-content-start" v-if="label" v-html="label"></label>

    <span v-if="info" v-tooltip="info">
      &nbsp;<InformationIcon :size="16" class="text-info" />
    </span>
    <VueCtkDateTimePicker
      color="#fb6340"
      locale="pt-BR"
      :formatted="formatted"
      :format="format"
      v-model="model"
      button-now-translation="Agora"
      :no-label="noLabel"
      :label="placeholder"
      @is-hidden="validate"
      :noClearButton="noClearButton"
      :custom-shortcuts="[]"
      v-bind="props"
      :minuteInterval="minuteInterval"
      :minDate="minDate"
      :maxDate="maxDate"
      :overlay="overlay"
      :onlyDate="onlyDate"
      :auto-close="autoClose"
    />
    <slot name="errors"></slot>
  </div>
</template>

<style>
.time-picker-overlay {
  z-index: 1040 !important;
}

.datetimepicker.visible[data-v-17c053f2] {
  z-index: 1041 !important;
}
</style>
