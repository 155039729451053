import * as types from './mutation-types';
import http from '~/http';

export const loadData = (context, payload) => {
  context.commit(types.SET_QUERY, payload);
  context.dispatch('setLoading', true);
  http
    .get('coupons', { params: context.state.query })
    .then(({ data }) => {
      context.commit(types.SET_DATA, data.data);
    })
    .finally(() => {
      context.dispatch('setLoading', false);
    });
};

export const setQuery = (context, payload) => {
  context.commit(types.SET_QUERY, payload);
  context.dispatch('loadData');
};

export const resetQuery = context => {
  context.commit(types.RESET_QUERY);
  context.dispatch('loadData');
};

export const setLoading = (context, payload) => {
  context.commit(types.SET_LOADING, payload);
};
