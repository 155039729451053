export default ({ $this, url, backgroundColor, width, height, widthLogo, heightLogo, x, y }) => {
  try {
    $this.loading = true;

    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');

    const logo = new Image();
    logo.onload = () => {
      canvas.width = width;
      canvas.height = height;
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(logo, x, y, widthLogo, heightLogo);

      const background = new Image();
      background.crossOrigin = 'anonymous';
      background.onload = () => {
        ctx.drawImage(background, 0, 0, width, height);
        $this.loading = false;
        $this.image = canvas.toDataURL('image/png');
      };
      background.src = url;
    };
    logo.src = $this.form.logo;
  } catch (err) {
    console.error(err);
    $this.$alert.show(
      'Oops',
      'Houve um erro ao gerar a imagem, tente novamente mais tarde.',
      'error',
    );
    $this.loading = false;
  }
};
