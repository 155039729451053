const Index = () => import('./components/index.vue');
const Create = () => import('./components/create-edit.vue');
const Prices = () => import('./components/prices.vue');

export default [
  {
    path: '/bairros',
    name: 'neighborhoods.index',
    meta: {
      title: 'Bairros',
    },
    component: Index,
  },
  {
    path: '/bairros/adicionar',
    name: 'neighborhoods.create',
    meta: {
      title: 'Adicionar bairro',
    },
    component: Create,
  },
  {
    path: '/bairros/:id/editar',
    name: 'neighborhoods.edit',
    meta: {
      title: 'Editar bairro',
    },
    component: Create,
  },
  {
    path: '/bairros-valores',
    name: 'neighborhoods.prices',
    meta: {
      title: 'Definir valores de entrega por bairro',
    },
    component: Prices,
  },
];
