export default (
  { street = null, number = null, neighborhood = null, complement = null, city = null, uf = null },
  showCity = false,
) => {
  if (showCity) {
    return [street, number, neighborhood, complement, `${city}-${uf}`].filter(Boolean).join(', ');
  }

  return [street, number, neighborhood, complement].filter(Boolean).join(', ');
};
