export default function formatDeliveryTime(time, get) {
  const split = time.split('/');

  if (split[1]) {
    return {
      min: split[0],
      max: split[1],
    };
  }

  return {
    min: time,
    max: time,
  };
}
