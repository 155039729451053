const checkPermission = (permission, permissions) => {
  if (typeof permission !== 'undefined') {
    if (permission === '') return true;

    const p = permission.indexOf('|') !== -1 ? permission.split('|') : [permission];

    return (
      p.find(perm => {
        const needed = perm.indexOf('&') !== -1 ? perm.split('&') : perm;

        if (Array.isArray(needed)) {
          return needed.every(need => permissions.indexOf(need) !== -1);
        }
        return permissions.indexOf(needed) !== -1 ? 1 : 0;
      }) !== undefined
    );
  }
  return false;
};

export default checkPermission;
