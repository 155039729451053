const Index = () => import('./components/index.vue');
const Create = () => import('./components/create.vue');

export default [
  {
    path: '/ads',
    name: 'ads.index',
    meta: {
      title: 'Publicidade no App',
    },
    component: Index,
  },
  {
    path: '/ads/create',
    name: 'ads.create',
    meta: {
      title: 'Criar publicidade',
    },
    component: Create,
  },
  {
    path: '/ads/:id/edit',
    name: 'ads.edit',
    meta: {
      title: 'Editar publicidade',
    },
    component: Create,
  },
];
