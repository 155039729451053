import Vue from 'vue';
import { formatDate as utilFormatDate } from '../utils';

export default Vue.filter('date-simple', (date, formatDate = null, fromNow = false) => {
  if (!date || date === '-') {
    return '-';
  }

  return utilFormatDate(date, 'dateFormatted');
});
