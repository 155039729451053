const drawToolbar = {
  actions: {
    title: 'Cancelar desenho',
    text: 'Cancelar',
  },
  finish: {
    title: 'Finalizar desenho',
    text: 'Finalizar',
  },
  undo: {
    title: 'Deletar último ponto desenhado',
    text: 'Deletar último ponto',
  },
  buttons: {
    polyline: 'Desenhar uma polilinha',
    polygon: 'Desenhar uma área',
    rectangle: 'Desenhar um retângulo',
    circle: 'Desenhar um círculo',
    marker: 'Desenhar un marcador',
    circlemarker: 'Desenhar um marcador circular',
  },
};

const drawHandlers = {
  circle: {
    tooltip: {
      start: 'Clique e arrastrar para desenhar o círculo.',
    },
    radius: 'Raio',
  },
  circlemarker: {
    tooltip: {
      start: 'Clique no mapa para inserir o marcador circular.',
    },
  },
  marker: {
    tooltip: {
      start: 'Clique no mapa para inserir o marcador.',
    },
  },
  polygon: {
    tooltip: {
      start: 'Clique para começar a desenhar a área.',
      cont: 'Clique para continuar desenhando a área.',
      end: 'Clique no primeiro ponto para fechar esta área.',
    },
  },
  polyline: {
    error: '<strong>Erro:</strong> as bordas de uma forma não podem atravessar!',
    tooltip: {
      start: 'Clique para começar a desenhar a linha.',
      cont: 'Clique para continuar desenhando a linha.',
      end: 'Clique no último ponto para terminar a linha.',
    },
  },
  rectangle: {
    tooltip: {
      start: 'Clique e arrastrar para desenhar o retângulo.',
    },
  },
  simpleshape: {
    tooltip: {
      end: 'Solte o mouse para terminar o desenho.',
    },
  },
};

const editToolbar = {
  actions: {
    save: {
      title: 'Salvar alterações.',
      text: 'Salvar',
    },
    cancel: {
      title: 'Cancelar edição, descarta todas as alterações.',
      text: 'Cancelar',
    },
    clearAll: {
      title: 'Limpar todas as áreas.',
      text: 'Limpar tudo',
    },
  },
  buttons: {
    edit: 'Editar áreas.',
    editDisabled: 'Nenhuma camada que editar.',
    remove: 'Deletar áreas.',
    removeDisabled: 'Nenhuma área para deletar.',
  },
};

const editHandlers = {
  edit: {
    tooltip: {
      text: 'Arraste os manipuladores ou marcadores para editar a figura.',
      subtext: 'Clique em cancelar para desfazer as alterações.',
    },
  },
  remove: {
    tooltip: {
      text: 'Clique em uma figura para removê-la',
    },
  },
};

export default {
  draw: {
    toolbar: drawToolbar,
    handlers: drawHandlers,
  },
  edit: {
    toolbar: editToolbar,
    handlers: editHandlers,
  },
};
