import check from './check';

const install = Vue => {
  Object.defineProperty(Vue.prototype, '$hide', {
    get() {
      return check(this);
    },
  });
};

export default { install };
