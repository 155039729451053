import Vue from 'vue';

export default Vue.filter('phone', value => {
  if (!value) {
    return '-';
  }

  let phone = value.toString();
  if (value) {
    if (phone.length === 11) {
      phone = `(${phone.substr(0, 2)}) ${phone.substr(2, 5)}-${phone.substr(7, 4)}`;
    } else {
      phone = `(${phone.substr(0, 2)}) ${phone.substr(2, 4)}-${phone.substr(6, 4)}`;
    }
  } else {
    phone = '-';
  }

  return phone;
});
