export default [
  {
    id: 'conta_corrente',
    name: 'Conta Corrente',
  },
  {
    id: 'conta_poupanca',
    name: 'Conta Poupança',
  },
  {
    id: 'conta_corrente_conjunta',
    name: 'Conta Corrente Conjunta',
  },
  {
    id: 'conta_poupanca_conjunta',
    name: 'Conta Poupança Conjunta',
  },
];
