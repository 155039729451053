import axios from 'axios';
import NProgress from 'nprogress';

NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: true,
});

export const createClient = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
});

createClient.interceptors.request.use(
  config => {
    NProgress.start();
    return config;
  },
  error => {
    NProgress.done();
    return Promise.reject(error);
  },
);

createClient.interceptors.response.use(
  response => {
    NProgress.done();
    return response;
  },
  error => {
    NProgress.done();
    return Promise.reject(error);
  },
);

export default createClient;
