import * as types from './mutations-types';
import {
  localStorageSetItem,
  localStorageGetItem,
  localStorageRemoveItem,
} from '~/shared/utils/local-storage';

export const setAuth = (context, payload) => {
  context.commit(types.SET_TOKEN, payload.token);
  context.commit(types.SET_USER, payload.user);
  context.commit(types.SET_PERMISSIONS, payload.permissions);
  context.commit(types.SET_CITIES, payload.cities);
  context.commit(types.SET_PLANS, payload.plans);

  localStorageSetItem('token', payload.token);
};

export const setFranchisee = (context, payload) => {
  context.commit(types.SET_FRANCHISEE, payload);
  localStorageSetItem('franchisee', { ...context.state.franchisee, ...payload });
};

export const setCity = (context, payload) => {
  context.commit(types.SET_CITY, payload);
  localStorageSetItem('city', { ...context.state.city, ...payload });
};

export const setCities = (context, payload) => {
  context.commit(types.SET_CITIES, payload);
};

export const setDataFromLocalStorage = context => {
  context.commit(types.SET_TOKEN, localStorageGetItem('token'));
  context.commit(types.SET_FRANCHISEE, localStorageGetItem('franchisee'));
  context.commit(types.SET_CITY, localStorageGetItem('city'));
};

export const updateDataAuth = (context, payload) => {
  context.commit(types.SET_USER, payload.user);
  context.commit(types.SET_CITIES, payload.cities);
  context.commit(types.SET_PERMISSIONS, payload.permissions);
  context.commit(types.SET_PLANS, payload.plans);

  context.state.cities.forEach(item => {
    if (item.id === context.state.city.id) {
      context.dispatch('setCity', item);
      context.commit(types.SET_CITY, item);
    }
  });
};

export const logout = context => {
  context.commit(types.SET_TOKEN, null);
  context.commit(types.SET_USER, {});
  context.commit(types.SET_PERMISSIONS, []);
  context.commit(types.SET_CITIES, []);
  context.commit(types.SET_FRANCHISEE, {});
  context.commit(types.SET_CITY, {});
  context.commit(types.SET_PLANS, []);

  localStorageRemoveItem('token');
  localStorageSetItem('franchisee');
  localStorageSetItem('city');
  localStorageSetItem('cities');
  localStorageSetItem('permissions');
};

export const addPlan = (context, payload) => {
  context.commit(types.ADD_PLAN, payload);
};

export const updatePlan = (context, payload) => {
  context.commit(types.UPDATE_PLAN, payload);
};

export const removePlan = (context, payload) => {
  context.commit(types.REMOVE_PLAN, payload);
};
