const Dashboard = () => import('./components/index.vue');

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      title: 'Dashboard',
    },
    component: Dashboard,
  },
];
