<script>
export default {
  name: 'Loading',
  props: {
    loading: { type: Boolean, default: false },
    size: { type: String, default: '' },
    type: { type: String, default: 'border' },
    color: { type: String, default: 'primary' },
    showMessage: { type: Boolean, default: false },
    message: { type: String, default: 'Carregando...' },
  },
};
</script>

<template>
  <div class="col-md-12 loading-bora" v-if="loading" style="text-align: center">
    <div
      :class="[
        {
          'spinner-grow': type === 'grow',
          'spinner-border': type === 'border',
          'spinner-grow-lg': type === 'grow' && size === 'lg',
          'spinner-border-lg': type === 'border' && size === 'lg',
        },
        `text-${color}`,
      ]"
      role="status"
    ></div>
    <span v-if="showMessage">{{ message }}</span>
  </div>
</template>

<style scoped>
.loading-bora {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.loading-bora span {
  margin-left: 10px;
}
</style>
