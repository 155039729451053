import isEmpty from 'lodash.isempty';

export const isLogged = state => !isEmpty(state.token);

export const token = state => state.token;

export const user = state => state.user;

export const permissions = state => state.permissions;

export const franchisee = state => state.franchisee;

export const licensed = state => state.franchisee;

export const city = state => state.city;

export const cities = state => state.cities;

export const totalCities = state => state.cities.length;

export const isSigning = state => {
  if (state.city) {
    return !Object.keys(state.city).length > 0;
  }

  if (state.cities.length > 0) {
    return true;
  }

  return true;
};
