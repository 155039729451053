<script>
export default {
  mounted() {
    document.body.classList.add('login-page');
  },

  destroyed() {
    document.body.classList.remove('login-page');
  },
};
</script>

<template>
  <div class="login-box">
    <router-view />
  </div>
</template>
