import * as types from './mutations-types';

export default {
  [types.SET_CATEGORIES](state, payload) {
    state.categories = payload;
  },

  // Create or edit existing category
  [types.CREATE_UPDATE_CATEGORY](state, payload) {
    const category = state.categories.find(cat => cat.id === payload.id);

    if (!category) {
      state.categories.push({ ...payload, products: payload.products || [] });
    } else {
      state.categories = state.categories.map(cat => {
        if (cat.id === category.id) {
          return { ...cat, ...payload };
        }
        return cat;
      });
    }
  },

  // Remove category the array
  [types.REMOVE_CATEGORY](state, payload) {
    state.categories = state.categories.filter(cat => cat.id !== payload.id);
  },

  /**
   * Products
   */
  [types.CREATE_UPDATE_PRODUCT](state, { category, product }) {
    if (category.id !== product.category_id) {
      const oldCategoryIndex = state.categories.findIndex(cat => cat.id === category.id);
      const newCategoryIndex = state.categories.findIndex(cat => cat.id === product.category_id);
      state.categories[oldCategoryIndex].products = state.categories[
        oldCategoryIndex
      ].products.filter(p => p.id !== product.id);
      state.categories[newCategoryIndex].products = [
        ...state.categories[newCategoryIndex].products,
        product,
      ];
    } else {
      state.categories = state.categories.map(cat => {
        if (cat.id === category.id) {
          const productExists = cat.products.find(pro => pro.id === product.id);

          if (productExists) {
            const products = cat.products.map(pro => {
              if (pro.id === product.id) {
                return { ...pro, ...product };
              }
              return pro;
            });
            return { ...cat, products };
          }

          cat.products.push(product);
          return cat;
        }
        return cat;
      });
    }
  },

  [types.REMOVE_PRODUCT](state, { category, product }) {
    const categoryIndex = state.categories.findIndex(cat => cat.id === category.id);

    state.categories[categoryIndex].products = state.categories[categoryIndex].products.filter(
      prod => prod.id !== product.id,
    );
  },
};
