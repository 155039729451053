export const initialState = () => ({
  loading: false,
  query: {
    search: '',
    start: '',
    end: '',
    page: 1,
  },
  data: {
    data: [],
    total: 0,
  },
});

export default {
  ...initialState(),
};
