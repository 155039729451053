const Index = () => import('./components/index.vue');

export default [
  {
    path: '/dados-bancarios',
    name: 'sub-accounts.index',
    meta: {
      title: 'Dados bancários',
    },
    component: Index,
  },
];
