export const getMinPriceProduct = (groups, priceProduct) => {
  const priceGroups = groups
    .map(g => {
      if (g.required) {
        if (g.min_items === 1) {
          return Math.min(...g.items.map(i => i.price));
        }
        return Math.min(...g.items.map(i => i.price)) * g.min_items;
      }
      return 0;
    })
    .reduce((a, b) => a + b, 0);
  return priceGroups + Number(priceProduct);
};

export const discountInMoney = (discount, discountType, minPriceProduct) => {
  if (discountType === 'money') {
    return Number(discount);
  }
  const discountPercentage = Number(discount) / 100;

  return Number(minPriceProduct) * discountPercentage;
};

export const discountInPercentage = (discount, discountType, minPriceProduct) => {
  if (discountType === 'percentage') {
    return discount;
  }
  const percentage = Number(minPriceProduct) - (Number(discount) * 100) / minPriceProduct;

  if (percentage === -Infinity || percentage === Infinity || isNaN(percentage)) {
    return 0;
  }

  return Number(minPriceProduct - percentage).toFixed(2);
};

export const getDiscountInMoneyByCoupon = (coupon, price) => {
  let discountType;
  let discount;
  if (coupon.price_discount) {
    discountType = 'money';
    discount = coupon.price_discount;
  } else {
    discountType = 'percentage';
    discount = coupon.percentage_discount;
  }

  return discountInMoney(discount, discountType, price);
};
