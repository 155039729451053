import Vue from 'vue';
import Transitions from 'vue2-transitions';
import money from 'v-money';
import VueTheMask from 'vue-the-mask';
import VTooltip from 'v-tooltip';
import VModal from 'vue-js-modal';
import Vuelidate from 'vuelidate';
import App from './App.vue';
import router from './router';
import store from './store';
import filters from './shared/filters/index';
import Alert from './shared/alert';
import Hide from './shared/hide';

import LayoutClean from './layout/Clean.vue';
import LayoutDefault from './layout/Default.vue';

import './components';
import './assets/icons';

/**
 * Import assets
 */
require('./assets');

// Layouts
Vue.component('clean-layout', LayoutClean);
Vue.component('app-layout', LayoutDefault);

Vue.use(Transitions);

Vue.use(VModal);

Vue.use(Alert);

Vue.use(Hide);

Vue.use(VueTheMask);

Vue.use(VTooltip);

Vue.use(Vuelidate);

Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: '',
  precision: 2,
  masked: true,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  filters,
  render: h => h(App),
}).$mount('#app');
