import { routes as authRoutes } from '~/pages/auth';
import { routes as companiesRoutes } from '~/pages/companies';
import { routes as couponsRoutes } from '~/pages/coupons';
import { routes as dashboardRoutes } from '~/pages/dashboard';
import { routes as deliverymanRoutes } from '~/pages/deliveryman';
import { routes as invoicesRoutes } from '~/pages/invoices';
import { routes as mediasRoutes } from '~/pages/marketing';
import { routes as neighborhoodsRoutes } from '~/pages/neighborhoods';
import { routes as notificationsMobileRoutes } from '~/pages/notifications-mobile';
import { routes as ordersRoutes } from '~/pages/orders';
import { routes as plansRoutes } from '~/pages/plans';
import { routes as adsRoutes } from '~/pages/publicity';
import { routes as reportsRoutes } from '~/pages/reports';
import { routes as settingsRoutes } from '~/pages/settings';
import { routes as subAccountsRoutes } from '~/pages/sub-accounts';
import { routes as usersRoutes } from '~/pages/users';

const rootRoutes = [
  {
    path: '/',
    name: 'home',
    redirect: '/dashboard',
  },
];

export default [
  ...rootRoutes,
  ...dashboardRoutes,
  ...authRoutes,
  ...companiesRoutes,
  ...plansRoutes,
  ...invoicesRoutes,
  ...notificationsMobileRoutes,
  ...couponsRoutes,
  ...usersRoutes,
  ...ordersRoutes,
  ...deliverymanRoutes,
  ...settingsRoutes,
  ...reportsRoutes,
  ...mediasRoutes,
  ...neighborhoodsRoutes,
  ...adsRoutes,
  ...subAccountsRoutes,
];
