const getMediaCategoryType = type => {
  switch (type) {
    case 'LICENSED':
      return 'licenciado';
    case 'COMPANY':
      return 'loja';
  }
};

const getItemType = type => {
  switch (type) {
    case 'COMPLEX':
      return 'Complexa';
    case 'SIMPLE':
      return 'Simples';
  }
};

const getItemMediaType = type => {
  switch (type) {
    case 'FEED':
      return 'Feed';
    case 'STORY':
      return 'Story';
  }
};

const mediaCategoryTypes = [
  {
    value: 'LICENSED',
    label: 'Licenciado',
  },
  {
    value: 'COMPANY',
    label: 'Loja',
  },
];

export { getItemMediaType, getItemType, getMediaCategoryType, mediaCategoryTypes };
