<script>
let typingTimer;

export default {
  name: 'Input',

  props: {
    maxLength: { default: null },
    editable: { default: false },
    clearable: { default: false },
    rangeHour: { default: '' },
    notAfter: { default: '' },
    notBefore: { default: '' },
    disabled: { default: false },
    refs: { default: '' },
    label: { default: '' },
    placeholder: { default: '' },
    errorInPlaceholder: { type: Boolean, default: false },
    type: { default: 'text' },
    mask: { default: '' },
    masked: { type: Boolean, default: false },
    name: { default: null, required: true, type: String },
    v: { default: '' },
    textValidations: { default: '' },
    value: { default: '' },
    focus: { default: '' },
    selectAll: { default: false },
    min: { default: null },
    max: { default: null },
    formSm: { default: false },
    rows: { type: Number, default: 10 },
    info: { type: String, default: '' },
    each: { type: Boolean, default: false },
  },
  data() {
    return {
      model: '',
      valid: false,
      isSuccess: false,
      validTouchMap: {},
    };
  },
  watch: {
    model() {
      const { model } = this;
      this.$emit('input', model);
    },

    value() {
      this.model = this.value;
    },
  },
  computed: {
    vName() {
      if (this.each) {
        return this.v;
      }
      return this.v.form[this.name];
    },

    errors() {
      if (this.v) {
        if (this.vName.$dirty && this.vName.$invalid) {
          const errors = [];
          Object.keys(this.textValidations).forEach(error => {
            errors.push(this.textValidations[error]);
          });
          return errors[0];
        }
      }
      return '';
    },

    isDanger() {
      if (this.v) {
        if (this.vName) {
          if (!this.model && !Object.keys(this.textValidations).find(x => x === 'required')) {
            return false;
          }
          return this.vName.$dirty && this.vName.$invalid;
        }
        return false;
      }
      return false;
    },
  },
  methods: {
    success() {
      this.$emit('blur');
      if (this.v) {
        setTimeout(() => {
          this.isSuccess = this.vName ? !this.vName.$invalid : !!this.model;
        }, 500);
      }
    },

    delayTouch() {
      if (this.v) {
        if (this.vName) {
          this.vName.$reset();
        }
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => (this.vName ? this.vName.$touch() : ''), 1000);
      } else {
        this.valid = false;
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
          this.valid = true;
        }, 1000);
      }
    },
  },
  mounted() {
    if (this.focus) {
      this.$refs.fieldFocus.focus();
      this.$nextTick(() => {
        if (this.selectAll) {
          this.$refs.fieldFocus.select();
        }
      });
    }
    this.model = this.value;
  },
};
</script>

<template>
  <div>
    <div
      :class="{
        'has-danger': isDanger,
        'form-group': label && !formSm,
      }"
    >
      <label class="form-control-label" v-if="label" :for="name">
        <span v-html="label"></span>
        &nbsp;
        <span v-if="info" v-tooltip="info">
          <InformationIcon :size="16" class="text-info" />
        </span>
      </label>

      <money
        :disabled="disabled"
        @blur="success()"
        v-model.trim="model"
        :name="name"
        :id="name"
        ref="fieldFocus"
        @input="delayTouch()"
        v-if="type === 'money'"
        :class="{
          'form-control-sm': formSm,
          'is-invalid': v && vName ? vName.$dirty && vName.$invalid : false,
        }"
        :placeholder="errorInPlaceholder ? (!!errors ? errors : placeholder) : placeholder"
        type="tel"
        class="form-control"
      />

      <textarea
        :maxLength="maxLength"
        :disabled="disabled"
        @blur="success()"
        :id="name"
        :rows="rows"
        class="form-control"
        :class="{
          'form-control-sm': formSm,
          'is-invalid': v && vName ? vName.$dirty && vName.$invalid : false,
        }"
        v-model.trim="model"
        :name="name"
        :ref="refs"
        @input="delayTouch()"
        v-else-if="type === 'textarea'"
        :placeholder="errorInPlaceholder ? (!!errors ? errors : placeholder) : placeholder"
      ></textarea>

      <input
        :maxLength="maxLength"
        :min="min"
        :max="max"
        :id="name"
        :class="{
          'form-control-sm': formSm,
          'is-invalid': v && vName ? vName.$dirty && vName.$invalid : false,
          'is-valid': isSuccess,
        }"
        :disabled="disabled"
        @blur="success()"
        v-model.trim="model"
        :name="name"
        ref="fieldFocus"
        @input="delayTouch()"
        v-else-if="!mask"
        :placeholder="errorInPlaceholder ? (!!errors ? errors : placeholder) : placeholder"
        :type="type"
        class="form-control"
        v-bind="$attrs"
      />

      <the-mask
        :disabled="disabled"
        @blur.native="success()"
        :id="name"
        v-else-if="mask"
        ref="fieldFocus"
        class="form-control"
        :class="{
          'form-control-sm': formSm,
          'is-invalid': v && vName ? vName.$dirty && vName.$invalid : false,
        }"
        :name="name"
        @input="delayTouch()"
        :placeholder="errorInPlaceholder ? (!!errors ? errors : placeholder) : placeholder"
        :mask="mask"
        :masked="masked"
        v-model="model"
      />

      <div v-if="!errorInPlaceholder">
        <div :key="index" v-for="(error, index) in Object.keys(textValidations)">
          <div
            class="invalid-feedback"
            v-if="v ? vName && vName[error] === false && vName['$dirty'] : false"
          >
            &times; {{ textValidations[error] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.invalid-feedback {
  display: block;
}
</style>
