const Index = () => import('./components/index.vue');
const DeliveryPrices = () => import('./components/delivery');
const Create = () => import('./components/create');
const Show = () => import('./components/show');
const Report = () => import('./components/report');
const ReportCompanies = () => import('./components/report-companies');
const DeliveryMap = () => import('./components/map');

export default [
  {
    path: '/entregadores',
    name: 'deliveryman.index',
    meta: {
      title: 'Entregadores',
    },
    component: Index,
  },
  {
    path: '/valores-de-entrega',
    name: 'deliveryman.prices',
    meta: {
      title: 'Valores de entrega',
    },
    component: DeliveryPrices,
  },
  {
    path: '/entregadores/novo',
    name: 'deliveryman.create',
    meta: {
      title: 'Adicionar entregador',
    },
    component: Create,
  },
  {
    path: '/entregadores/relatorio',
    name: 'deliveryman.report',
    meta: {
      title: 'Relatório de entregas',
    },
    component: Report,
  },
  {
    path: '/entregadores/relatorio-empresas',
    name: 'deliveryman.report.companies',
    meta: {
      title: 'Relatório de entregas',
    },
    component: ReportCompanies,
  },
  {
    path: '/entregadores/mapa',
    name: 'deliveryman.map',
    meta: {
      title: 'Mapa de entregas',
    },
    component: DeliveryMap,
  },
  {
    path: '/entregadores/:id',
    name: 'deliveryman.show',
    meta: {
      title: 'Ver entregador',
    },
    component: Show,
  },
];
