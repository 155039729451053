export default payment_method => {
  switch (payment_method) {
    case 'BILLET':
      return 'boleto';
    case 'PIX':
      return 'pix';
    case 'PIX_TRANSFER':
      return 'pix off';
    case 'RECEIVED_IN_CASH':
      return 'dinheiro';
    case 'CREDIT-CARD':
      return 'cartão de crédito';
    case 'CREDIT_CARD':
      return 'cartão de crédito';
    default:
      return 'desconhecido';
  }
};
