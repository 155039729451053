export default ({
  title,
  message,
  callback,
  confirmButtonText = 'Sim, remover',
  cancelButtonText = 'Cancelar',
  showLoaderOnConfirm = true,
  showCancelButton = true,
  confirmButtonColor = '#d33',
  icon = 'warning',
}) => {
  window.swal.fire({
    title,
    html: message,
    showCancelButton,
    confirmButtonText,
    cancelButtonText,
    preConfirm: callback,
    showLoaderOnConfirm,
    confirmButtonColor,
    icon,
  });
};
