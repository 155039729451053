export default [
  { value: 'America/Rio_Branco', label: '(UTC-05:00) America/Rio_Branco' },
  { value: 'America/Boa_Vista', label: '(UTC-04:00) America/Boa_Vista' },
  { value: 'America/Campo_Grande', label: '(UTC-04:00) America/Campo_Grande' },
  { value: 'America/Cuiaba', label: '(UTC-04:00) America/Cuiaba' },
  { value: 'America/Manaus', label: '(UTC-04:00) America/Manaus' },
  { value: 'America/Porto_Velho', label: '(UTC-04:00) America/Porto_Velho' },
  { value: 'America/Bahia', label: '(UTC-03:00) America/Bahia' },
  { value: 'America/Belem', label: '(UTC-03:00) America/Belem' },
  { value: 'America/Fortaleza', label: '(UTC-03:00) America/Fortaleza' },
  { value: 'America/Maceio', label: '(UTC-03:00) America/Maceio' },
  { value: 'America/Recife', label: '(UTC-03:00) America/Recife' },
  { value: 'America/Santarem', label: '(UTC-03:00) America/Santarem' },
  { value: 'America/Sao_Paulo', label: '(UTC-03:00) America/Sao_Paulo' },
  { value: 'America/Noronha', label: '(UTC-02:00) America/Noronha' },
];
