const Index = () => import('./components/index.vue');
const Show = () => import('./components/show.vue');
const CreateMediaStory = () => import('./components/create-media-story.vue');
const CreateMediaFeed = () => import('./components/create-media-feed.vue');

export default [
  {
    path: '/marketing',
    name: 'marketing.index',
    meta: {
      title: 'Marketing',
    },
    component: Index,
    children: [
      {
        path: '/marketing/:type/:slug',
        name: 'marketing.show',
        meta: {
          title: 'Marketing',
        },
        component: Show,
      },
      {
        path: '/marketing/stories',
        name: 'marketing.stories.index',
        meta: {
          title: 'Criar mídia de stories',
        },
        component: CreateMediaStory,
      },
      {
        path: '/marketing/feed',
        name: 'marketing.feed.index',
        meta: {
          title: 'Criar mídia de feed',
        },
        component: CreateMediaFeed,
      },
    ],
  },
];
