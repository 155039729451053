export const SET_TOKEN = 'auth/setToken';

export const SET_USER = 'auth/setUser';

export const SET_PERMISSIONS = 'auth/setPermissions';

export const SET_FRANCHISEE = 'auth/setFranchisee';
export const SET_CITY = 'auth/setCity';

export const SET_CITIES = 'auth/setCities';

export const UPDATE_USER = 'auth/updateUser';
export const SET_PLANS = 'auth/setPlans';
export const ADD_PLAN = 'auth/addPlan';
export const UPDATE_PLAN = 'auth/updatePlan';
export const REMOVE_PLAN = 'auth/removePlan';
