const Companies = () => import('./components/index.vue');
const CreateEdit = () => import('./components/create-edit');
const Show = () => import('./components/show/index.vue');
const AddUser = () => import('./components/show/users/attach-user.vue');
const UpdateBank = () => import('./components/edit-bank');

const CompaniesInvoicesIndex = () => import('./components/invoices');
const CompaniesInvoicesShow = () => import('./components/invoices/show');

export default [
  {
    path: '/empresas',
    name: 'companies.index',
    meta: {
      title: 'Empresas',
    },
    component: Companies,
  },
  {
    path: '/empresas/novo',
    name: 'companies.create',
    meta: {
      title: 'Nova empresa',
    },
    component: CreateEdit,
  },
  /**
   * Invoices
   */
  {
    path: '/empresas/faturas',
    name: 'companies.invoices.index',
    meta: {
      title: 'Faturas das empresas',
    },
    component: CompaniesInvoicesIndex,
  },
  {
    path: '/empresas/faturas/:id',
    name: 'companies.invoices.show',
    meta: {
      title: 'Faturas das empresas',
    },
    component: CompaniesInvoicesShow,
  },
  /**
   * End Invoices
   */
  {
    path: '/empresas/editar/:id',
    name: 'companies.edit',
    meta: {
      title: 'Alterar empresa',
    },
    component: CreateEdit,
  },
  {
    path: '/empresas/:id',
    name: 'companies.show',
    meta: {
      title: 'Ver empresa',
    },
    component: Show,
  },
  {
    path: '/empresas/:id/editar-banco',
    name: 'companies.update.bank',
    meta: {
      title: 'Editar conta bancária de empresa',
    },
    component: UpdateBank,
  },
  {
    path: '/empresas/:id/usuarios/novo',
    name: 'companies.users.add',
    meta: {
      title: 'Adicionar usuário à empresa',
    },
    component: AddUser,
  },
];
