var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'has-error': _vm.isDanger,
    'form-group': _vm.labelSelect && !_vm.formSm,
  }},[(_vm.label)?_c('label',{attrs:{"for":this.name}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.info)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.info),expression:"info"}]},[_c('InformationIcon',{staticClass:"text-info",attrs:{"size":16}})],1):_vm._e()]):_vm._e(),_c('select',{staticClass:"select2-hidden-accessible",class:{
      'form-control-sm': _vm.formSm,
      'is-invalid': _vm.v && _vm.vName ? _vm.vName.$dirty && _vm.vName.$invalid : false,
    },attrs:{"id":`select_${_vm.name}`,"name":_vm.name,"multiple":_vm.multiple}},[_c('option',{attrs:{"value":""}})]),(!_vm.errorInPlaceholder)?_c('div',_vm._l((Object.keys(_vm.textValidations)),function(error,index){return _c('div',{key:index},[(_vm.v ? _vm.vName && _vm.vName[error] === false && _vm.vName['$dirty'] : false)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" × "+_vm._s(_vm.textValidations[error])+" ")]):_vm._e()])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }