const statuses = {
  opened: {
    label: 'em aberto',
    color: 'warning',
    icon: 'fa-spinner',
  },
  confirmed: {
    label: 'confirmado',
    color: 'info',
    icon: 'fa-check',
  },
  canceled: {
    label: 'cancelado',
    color: 'danger',
    icon: 'fa-times',
  },
  production: {
    label: 'em produção',
    color: 'info',
    icon: 'fa-spinner',
  },
  pending_payment: {
    label: 'aguardando pag.',
    color: 'dark',
    icon: 'fa-credit-card',
  },
  process_payment: {
    label: 'processando pag.',
    color: 'dark',
    icon: 'fa-credit-card',
  },
  sent: {
    label: 'enviado',
    color: 'success',
    icon: 'fa-motorcycle',
  },
  completed: {
    label: 'concluído',
    color: 'success',
    icon: 'fa-check-circle',
  },
  ready_for_pickup: {
    label: 'pronto para retirada na loja',
    color: 'info',
    icon: 'fa-walking',
  },
  order_caught: {
    label: 'coletado pelo entregador',
    color: 'info',
    icon: 'fa-people-carry',
  },
  ready_delivery: {
    label: 'aguardando entregador',
    color: 'info',
    icon: 'fa-spinner',
  },
  delivery_arrived: {
    label: 'entregador no cliente',
    color: 'success',
    icon: 'fa-people-arrows',
  },
  order_accepted: {
    label: 'entregador atribuído',
    color: 'info',
    icon: 'fa-user-check',
  },
  deliveryman_arrived_company: {
    label: 'entregador na loja',
    color: 'info',
    icon: 'fa-building',
  },
};

const getStatusBadge = (status, badge = true, icon = true) => {
  if (!statuses[status]) {
    return status;
  }

  if (!badge) {
    return statuses[status].label;
  }
  let label;

  if (icon) {
    label = `<i class="fa ${statuses[status].icon}"></i> ${statuses[status].label}`;
  } else {
    label = statuses[status].label;
  }

  return `<span class="badge badge-${statuses[status].color}">${label}</span>`;
};

export default getStatusBadge;
