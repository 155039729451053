const Index = () => import('./components/index.vue');
const Create = () => import('./components/create');
const Show = () => import('./components/show');
const Edit = () => import('./components/edit');

export default [
  {
    path: '/cupons',
    name: 'coupons.index',
    meta: {
      title: 'Cupons',
    },
    component: Index,
  },
  {
    path: '/cupons/novo',
    name: 'coupons.create',
    meta: {
      title: 'Criar Cupom',
    },
    component: Create,
  },
  {
    path: '/cupons/recharge',
    name: 'coupons.recharge',
    meta: {
      title: 'Fazer recarga de cupons',
    },
    component: Show,
  },
  {
    path: '/cupons/:id',
    name: 'coupons.show',
    meta: {
      title: 'Ver dados do cupom',
    },
    component: Show,
  },
  {
    path: '/cupons/:id/editar',
    name: 'coupons.edit',
    meta: {
      title: 'Editar cupom',
    },
    component: Edit,
  },
];
